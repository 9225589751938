/*
td, th {
  border-style: unset;
  padding: .25rem;
}
th {
  background-color: black;
  color: lightblue;
}
a {
  text-decoration: none;
}
*/

ul.hmenu {
  list-style-type: none;
  /* margin: 1rem 0; */
  display: inline-flex;
  width: 100%;
  padding-inline-start: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  background-color: #222;
  border-radius: 1rem;
}

ul.hmenu li {
  float: left;
}

ul.hmenu li a {
  display: block;
  border-radius: 1rem;
  color: lightblue;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  text-decoration: none;
}

ul.hmenu li a.active {
  color: lightblue;
  font-weight: bold;
  background-color: black;
}

/*
ul.hmenu-level2 {
  background-color: #000;
}

ul.hmenu-level2 li a.active {
  color: white;
  background-color: navy;
}
*/


.pulsate {
  -webkit-animation: pulse 2s ease-in;
  -moz-animation: pulse 2s ease-in;
   animation: pulse 2s ease-in;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;    
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    opacity: 0.3;
  }
  25% {
    -webkit-transform: scale(1.1);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(1.2);
    opacity: 0.7;
  }
  75% {
    -webkit-transform: scale(1.3);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.3;
  }
}